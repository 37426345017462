import { Popup } from "../_common/scripts/popup";
import { Wheel } from "../_common/scripts/wheel";
import { ProdLink } from "../_common/scripts/prod-link";

const WINNING_SEGMENTS = [2, 1];

class App {
  private readonly popup = new Popup();
  private readonly wheel = new Wheel(8, WINNING_SEGMENTS);
  private readonly prodLink = new ProdLink();

  private attemptsCountElList = document.querySelectorAll(".js-attempts-count");

  private attemptsCount = WINNING_SEGMENTS.length;

  constructor() {
    this.updateAttempts();
  }

  closePopup(): void {
    this.popup.close();
  }

  handleSpinAgainClick(): void {
    this.closePopup();
    void this.spin();
  }

  async spin(): Promise<void> {
    if (this.wheel.isSpinning) {
      return;
    }

    this.attemptsCount--;
    this.updateAttempts();

    this.handleSpinResult(await this.wheel.spin());
  }

  private handleSpinResult(winningSegment: number): void {
    if (winningSegment === 2) {
      this.popup.open("#popup-bonus-1");
    } else if (winningSegment === 1) {
      this.popup.open("#popup-bonus-2");

      requestAnimationFrame(() => this.prodLink.updateLinks());
    }
  }

  private updateAttempts(): void {
    this.attemptsCountElList.forEach(
      (el) => (el.innerHTML = "" + this.attemptsCount),
    );
  }
}

// @ts-ignore
window.app = new App();
