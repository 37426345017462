export class ProdLink {
  constructor() {
    this.updateLinks();
  }

  updateLinks(): void {
    const prodLinkElList = document.querySelectorAll(".js-prod-link");

    prodLinkElList.forEach((el) => {
      const linkUrl = el.getAttribute("href");
      const finalUrl = linkUrl.includes("?")
        ? linkUrl + location.search.replace("?", "&")
        : linkUrl + location.search;

      el.setAttribute("href", finalUrl);
    });
  }
}
