export class Popup {
  private readonly popupEl = document.querySelector(".js-popup");
  private readonly popupBodyEl = this.popupEl.querySelector(".js-popup-body");

  constructor() {
    this.close();
  }

  open(selector: string): void {
    this.popupBodyEl.innerHTML = document.querySelector(selector).innerHTML;
    this.popupEl.setAttribute("style", "visibility: visible");
  }

  close(): void {
    this.popupEl.setAttribute("style", "visibility: hidden");
  }
}
