const fullRotationsCount = 20;

export class Wheel {
  private readonly wheelEl: HTMLElement = document.querySelector(".js-wheel");

  private readonly degreesPerSegment: number;
  private readonly fullRotationsDegrees = fullRotationsCount * 360;

  private spinsCount = 0;
  private spinInProgress = false;

  constructor(
    private readonly segmentsCount: number,
    private readonly winningSegments: number[],
  ) {
    this.degreesPerSegment = 360 / this.segmentsCount;
  }

  get isSpinning(): boolean {
    return this.spinInProgress;
  }

  async spin(seconds: number = 8): Promise<number> {
    const targetSegment =
      this.winningSegments[this.spinsCount] ??
      (this.winningSegments[this.winningSegments.length - 1] || 0);
    const normalizedDegreesToTargetSegment =
      this.degreesPerSegment * targetSegment;
    const degreesToTargetSegment =
      this.fullRotationsDegrees + normalizedDegreesToTargetSegment;

    this.spinInProgress = true;
    this.wheelEl.style.transition = `${seconds}s transform cubic-bezier(0.76, 0, 0.24, 1)`;
    await this.nextRender();
    this.wheelEl.style.transform = `rotateZ(${-degreesToTargetSegment}deg)`;
    await this.seconds(seconds);
    this.wheelEl.style.transition = `none`;
    await this.nextRender();
    this.wheelEl.style.transform = `rotateZ(${-normalizedDegreesToTargetSegment}deg)`;

    this.spinsCount++;
    this.spinInProgress = false;

    return targetSegment;
  }

  private async nextRender(): Promise<void> {
    return await new Promise((resolve) => {
      requestAnimationFrame(() => resolve());
    });
  }

  private async seconds(seconds: number): Promise<void> {
    return await new Promise((resolve) => {
      setTimeout(() => resolve(), seconds * 1000);
    });
  }
}
